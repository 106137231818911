import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import line from '.././assets/images/line.png'
import TableComponent from './RequirementTable'
import { labels } from '../TableHeading/RequriementHeading'

const labelfontfamily = 'Times-Bold'

const AdditionalDetails = ({
    note,
    remarks,
    requirements,
    requirementStatus,
    type,
    remarkStatus,
    title,
    groupName,
    titleName,
}) => {
    return (
        <View
            style={{
                marginTop: '4px',
                marginBottom:
                    type === 'report' && title === 'Drinking Water (Bore Water)'
                        ? '100px'
                        : null,
            }}
            wrap={false}
        >
            {requirementStatus == true && (
                // requirements &&
                // requirements?.length > 0 &&
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                    }}
                >
                    {requirements &&
                        requirements &&
                        groupName !== 'Bricks Compressive Strength' &&
                        groupName !== 'Compressive Strength' &&
                        ((groupName !== 'Block Compressive Strength' &&
                            titleName !== 'Solid Blocks') ||
                            (groupName !== 'Block Compressive Strength' &&
                                titleName === 'Solid Blocks') ||
                            (groupName === 'Block Compressive Strength' &&
                                titleName === 'Paver blocks')) &&
                        groupName !==
                            'Coarse Aggregate Sieve Analysis - 20mm' &&
                        groupName !==
                            'Coarse Aggregate Sieve Analysis - 12.5mm' &&
                        groupName !==
                            'Fine Aggregate Sieve Analysis - P sand' &&
                        groupName !==
                            'Fine Aggregate Sieve Analysis - M sand' &&
                        groupName !==
                            'Fine Aggregate Sieve Analysis - River sand' && (
                            <Text
                                style={{
                                    fontSize: 10,
                                    fontFamily: labelfontfamily,
                                    marginRight: '8px',
                                }}
                            >
                                Requirements :
                            </Text>
                        )}

                    {(groupName === 'Bricks Compressive Strength' ||
                        groupName === 'Compressive Strength' ||
                        (groupName === 'Block Compressive Strength' &&
                            titleName === 'Solid Blocks') ||
                        groupName ===
                            'Coarse Aggregate Sieve Analysis - 20mm' ||
                        groupName ===
                            'Coarse Aggregate Sieve Analysis - 12.5mm' ||
                        groupName ===
                            'Fine Aggregate Sieve Analysis - P sand' ||
                        groupName ===
                            'Fine Aggregate Sieve Analysis - M sand' ||
                        groupName ===
                            'Fine Aggregate Sieve Analysis - River sand') && (
                        <Text
                            style={{
                                fontSize: 10,
                                fontFamily: labelfontfamily,
                                marginRight: '8px',
                            }}
                        >
                            Requirements :
                        </Text>
                    )}

                    {(groupName === 'Bricks Compressive Strength' ||
                        groupName === 'Compressive Strength' ||
                        (groupName === 'Block Compressive Strength' &&
                            titleName === 'Solid Blocks') ||
                        groupName ===
                            'Coarse Aggregate Sieve Analysis - 20mm' ||
                        groupName ===
                            'Coarse Aggregate Sieve Analysis - 12.5mm' ||
                        groupName ===
                            'Fine Aggregate Sieve Analysis - P sand' ||
                        groupName ===
                            'Fine Aggregate Sieve Analysis - M sand' ||
                        groupName ===
                            'Fine Aggregate Sieve Analysis - River sand') && (
                        <TableComponent groupName={groupName} />
                    )}
                    {groupName !== 'Bricks Compressive Strength' &&
                        groupName !== 'Compressive Strength' &&
                        ((groupName !== 'Block Compressive Strength' &&
                            titleName !== 'Solid Blocks') ||
                            (groupName !== 'Block Compressive Strength' &&
                                titleName === 'Solid Blocks') ||
                            (groupName === 'Block Compressive Strength' &&
                                titleName === 'Paver blocks')) &&
                        groupName !==
                            'Coarse Aggregate Sieve Analysis - 20mm' &&
                        groupName !==
                            'Coarse Aggregate Sieve Analysis - 12.5mm' &&
                        groupName !==
                            'Fine Aggregate Sieve Analysis - P sand' &&
                        groupName !==
                            'Fine Aggregate Sieve Analysis - M sand' &&
                        groupName !==
                            'Fine Aggregate Sieve Analysis - River sand' &&
                        requirements &&
                        requirements && <Text>{requirements}</Text>}
                </View>
            )}
            {remarkStatus == true && remarks?.length > 1 && (
                <View
                    style={{
                        flexDirection: 'row',
                        marginTop: '1px',
                        flexWrap: 'wrap',
                    }}
                >
                    <Text
                        style={{
                            fontSize: '10px',
                            fontFamily: labelfontfamily,
                            marginRight: '8px',
                        }}
                    >
                        Remarks :
                    </Text>
                    <Text
                        style={{
                            fontSize: 10,
                        }}
                    >
                        {remarks}
                    </Text>
                </View>
            )}
            {note &&
                note?.note?.length > 1 &&
                (type === 'report' || type === 'reportOnly') && (
                    <View
                        style={{
                            flexDirection: 'row',
                            marginTop: '1px',
                            marginBottom: '1px',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Text
                            style={{
                                fontSize: '10px',
                                fontFamily: labelfontfamily,
                                marginRight: '8px',
                            }}
                        >
                            Note :
                        </Text>
                        <Text
                            style={{
                                fontSize: 10,
                            }}
                        >
                            {note?.note ?? ''}
                        </Text>
                    </View>
                )}

            {/* <View style={{ marginBottom: '2px', marginTop: '2px' }} fixed>
                <Image src={line} />
            </View> */}
            {groupName !==
                'Equivalent Cube Compressive Stregnth Results of Concrete Core' && (
                <View style={{ marginBottom: '7px', marginTop: '2px' }} fixed>
                    <Image src={line} />
                </View>
            )}

            {/* <View style={{ maxWidth: '100%' }}>
                <Text
                    style={{
                        fontSize: '9',
                        fontFamily: 'Times-Bold',
                        paddingBottom: '10',
                    }}
                >
                    *As Furnished by the customer
                </Text>
            </View> */}
        </View>
    )
}

export default AdditionalDetails
